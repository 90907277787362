import { Image, InternalLink, Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { WORKPLACE_REFERRALS_WITH_ACCOUNTS_PATH } from "@src/appV2/Accounts/WorkplaceReferrals";
import { useWorkerToWorkplaceAffiliateExperiment } from "@src/appV2/Accounts/WorkplaceReferrals/hooks/useWorkerToWorkplaceAffiliateExperiment";
import cbhBunnyLogo from "@src/appV2/lib/assets/images/cbhBunny.svg";

export function EmptyMyShiftsPlaceholder() {
  const isWorkerToWorkplaceAffiliateProgramEnabled = useWorkerToWorkplaceAffiliateExperiment();

  return (
    <Stack alignItems="center" spacing={2} textAlign="center">
      <Image alt="Clipboard Health logo" src={cbhBunnyLogo} width="160px" />
      <Text variant="body2">You have not booked any upcoming shifts!</Text>
      <Text variant="body2">Tap Find Shifts on the main menu to look for shifts you can book.</Text>
      {isWorkerToWorkplaceAffiliateProgramEnabled && (
        <Text variant="body2">
          Don&apos;t see any shifts near you? Earn up to $1000 with our{" "}
          <InternalLink
            aria-label="Affiliate Program"
            to={`${WORKPLACE_REFERRALS_WITH_ACCOUNTS_PATH}?source=myShifts`}
          >
            Workplace Affiliate Program
          </InternalLink>
          !
        </Text>
      )}
    </Stack>
  );
}
